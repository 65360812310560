import styled from 'styled-components';
import theme from '../../theme';

export const TileNoVideo = styled.div`
  display: flex;
  margin: 10px;
  flex-wrap: nowrap;
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${theme.colors.blue};
  @media (max-width: 1075px) {
    flex-direction: column;
    margin: ${props => props.hide ? 0 : '2px'};
    display: ${props => props.hide ? 'none' : 'flex'};
  }


`;