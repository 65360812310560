import styled from 'styled-components';
import theme from '../../theme';

export const Status = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 4px;
  z-index: 10;
  background: ${theme.colors.blackOpacity};
`;