import styled from 'styled-components';
import theme from '../../theme';

export const Tile = styled.div`
  display: flex;
  margin: 10px;
  flex-wrap: nowrap;
  width: 80%;
  position: relative;
  background: ${theme.colors.blue};
  @media (max-width: 1075px) {
    flex-direction: column;
    margin: ${props => props.hide ? 0 : '2px'};
    width: ${props => props.landscape ? 'auto' : '100%'};
    height: ${props => props.landscape ? '100%' : 'auto'};
    display: ${props => props.hide ? 'none' : 'flex'};
  }


`;