import React, { useEffect, useMemo, useRef } from 'react';
import { Video } from '../Styled/Video';
import { Tile } from '../Styled/Tile/Tile';
import useScreenOrientation from '../hooks/useScreenOrientation';
import { SCREEN_ORIENTATIONS } from '../utils';
import { Status } from '../Styled/Tile/Status';
import { Overlay } from '../Styled/Tile/Overlay';
import { TileNoVideo } from '../Styled/Tile/TileNoVideo';
import { isMobileOnly } from 'react-device-detect';

const getTrackUnavailableMessage = (kind, trackState) => {
  if (!trackState) return;
  switch (trackState.state) {
    case 'blocked':
      if (trackState.blocked.byPermissions) {
        return `${kind} permission denied`;
      } else if (trackState.blocked.byDeviceMissing) {
        return `${kind} device missing`;
      }
      return `${kind} blocked`;
    case 'off':
      if (trackState.off.byUser) {
        return `${kind} muted`;
      } else if (trackState.off.byBandwidth) {
        return `${kind} muted to save bandwidth`;
      }
      return `${kind} off`;
    case 'sendable':
      return `${kind} not subscribed`;
    case 'loading':
      return `${kind} loading...`;
    case 'interrupted':
      return `${kind} interrupted`;
    case 'playable':
      return null;
    default:
      return null;
  }
};
const TileCall = (props) => {
  const videoEl = useRef(null);
  const audioEl = useRef(null);
  const screenOrientation = useScreenOrientation();
  console.log(screenOrientation);

  const videoTrack = useMemo(() => {
    return props.videoTrackState && props.videoTrackState.state === 'playable'
      ? props.videoTrackState.track
      : null;
  }, [props.videoTrackState]);

  const audioTrack = useMemo(() => {
    return props.audioTrackState && props.audioTrackState.state === 'playable'
      ? props.audioTrackState.track
      : null;
  }, [props.audioTrackState]);

  const videoUnavailableMessage = useMemo(() => {
    return getTrackUnavailableMessage('video', props.videoTrackState);
  }, [props.videoTrackState]);

  const audioUnavailableMessage = useMemo(() => {
    return getTrackUnavailableMessage('audio', props.audioTrackState);
  }, [props.audioTrackState]);

  const isLandscape = () => {
    return screenOrientation === SCREEN_ORIENTATIONS.LANDSCAPE;
  };

  /**
   * When video track changes, update video srcObject
   */
  useEffect(() => {
    videoEl.current &&
    (videoEl.current.srcObject = new MediaStream([videoTrack]));
  }, [videoTrack]);

  /**
   * When audio track changes, update audio srcObject
   */
  useEffect(() => {
    audioEl.current &&
    (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [audioTrack]);

  function getVideoComponent() {
    return videoTrack && <Video autoPlay landscape={isLandscape()} muted playsInline ref={videoEl} />;
  }

  function getAudioComponent() {
    return (
      !props.isLocalPerson &&
      audioTrack && <audio autoPlay playsInline ref={audioEl} />
    );
  }

  function getOverlayComponent() {
    // Show overlay when video is unavailable. Audio may be unavailable too.
    return (
      videoUnavailableMessage && (
        <Overlay>
          {videoUnavailableMessage}
          {audioUnavailableMessage && (
            <>
              <br />
              {audioUnavailableMessage}
            </>
          )}
        </Overlay>
      )
    );
  }

  function getCornerMessageComponent() {
    // Show corner message when only audio is unavailable.
    return (
      !props.disableCornerMessage &&
      audioUnavailableMessage &&
      !videoUnavailableMessage && (
        <p>{audioUnavailableMessage}</p>
      )
    );
  }

  const hide = isLandscape() && isMobileOnly && props.isLocalPerson;
  const name = props.isLocalPerson ? 'local Guest' : 'Guest';
  console.log('isLandscape()', isLandscape());
  console.log('isMobileOnly', isMobileOnly);
  console.log('props.isLocalPerson', props.isLocalPerson);


  console.log('hide', hide);
  // if (hide) {
  //   console.log("hide");
  //   return <></>;
  // }

  return (
    <>
      {videoUnavailableMessage
        ?
        (<TileNoVideo landscape={isLandscape()} hide={hide}>
          <Status>
            {name}
          </Status>
          {getOverlayComponent()}
        </TileNoVideo>)
        :
        <Tile landscape={isLandscape()} hide={hide}>
          <Status>
            {name}
          </Status>
          {getOverlayComponent()}
          {getVideoComponent()}
          {getAudioComponent()}
          {getCornerMessageComponent()}
        </Tile>
      }
    </>
  );
};
export default TileCall;
