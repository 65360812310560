const PARTICIPANTS_CHANGE = 'PARTICIPANTS_CHANGE';
const CAM_OR_MIC_ERROR = 'CAM_OR_MIC_ERROR';
const FATAL_ERROR = 'FATAL_ERROR';

const initialState = {
  callItems: {
    local: {
      videoTrackState: null,
      audioTrackState: null,
    },
  },
  camOrMicError: null,
  fatalError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case PARTICIPANTS_CHANGE:
      const callItems = getCallItems(action.participants);
      return {
        ...state,
        callItems,
      };
    case CAM_OR_MIC_ERROR:
      return { ...state, camOrMicError: action.message };
    case FATAL_ERROR:
      return {...state, fatalError: action.message };
    default:
      throw new Error();
  }
};

function isLocal(id) {
  return id === 'local';
}

function getCallItems(participants) {
  let callItems = { ...initialState.callItems }; // Ensure we *always* have a local participant
  for (const [id, participant] of Object.entries(participants)) {
    callItems[id] = {
      videoTrackState: participant.tracks.video,
      audioTrackState: participant.tracks.audio,
    };
    // if (shouldIncludeScreenCallItem(participant)) {
    //   callItems[id + '-screen'] = {
    //     videoTrackState: participant.tracks.screenVideo,
    //     audioTrackState: participant.tracks.screenAudio,
    //   };
    // }
  }
  return callItems;
}

export {
  PARTICIPANTS_CHANGE,
  CAM_OR_MIC_ERROR,
  FATAL_ERROR,
  initialState,
  isLocal,
  reducer
};