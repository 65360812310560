import { createGlobalStyle } from "styled-components";
import theme from '../theme';

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        font-family: sans-serif;
        color: #fff;
        background: ${theme.colors.blueLight};
    }
`;