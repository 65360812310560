import React, { useContext, useEffect, useReducer } from 'react';
import CallObjectContext from '../CallObjectContext';
import { CAM_OR_MIC_ERROR, FATAL_ERROR, initialState, isLocal, PARTICIPANTS_CHANGE, reducer } from '../store';
import TileCall from './TileCall';

const Call = ({roomUrl}) => {
  const callObject = useContext(CallObjectContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!callObject) return;

    const events = [
      'participant-joined',
      'participant-updated',
      'participant-left',
    ];

    function handleNewParticipantsState(event) {
      event && console.log(event);
      dispatch({
        type: PARTICIPANTS_CHANGE,
        participants: callObject.participants(),
      });
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewParticipantsState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewParticipantsState);
      }
    };
  }, [callObject]);

  useEffect(() => {
    if (!callObject) return;

    function handleCameraErrorEvent(event) {
      console.log(event);
      dispatch({
        type: CAM_OR_MIC_ERROR,
        message:
          (event && event.errorMsg && event.errorMsg.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no camera error when callObject
    // is first assigned.

    callObject.on('camera-error', handleCameraErrorEvent);

    return function cleanup() {
      callObject.off('camera-error', handleCameraErrorEvent);
    };
  }, [callObject]);

  useEffect(() => {
    if (!callObject) return;

    function handleErrorEvent(e) {
      console.log(e);
      dispatch({
        type: FATAL_ERROR,
        message: (e && e.errorMsg) || 'Unknown',
      });
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    callObject.on('error', handleErrorEvent);

    return function cleanup() {
      callObject.off('error', handleErrorEvent);
    };
  }, [callObject]);
  const getTiles = () => {
    let tiles = [];
    Object.entries(state.callItems).forEach(([id, callItem]) => {
      const tile = (
        <TileCall
          key={id}
          videoTrackState={callItem.videoTrackState}
          audioTrackState={callItem.audioTrackState}
          isLocalPerson={isLocal(id)}
          disableCornerMessage={true}
        />
      );
      tiles.push(tile);
    });
    return tiles;
  }
  const tiles = getTiles();
  return (
   <>
      {tiles}
   </>
  );
}
export default Call;