import React, { useCallback, useEffect, useState } from 'react';
import CallObjectContext from '../CallObjectContext';
import { useParams } from 'react-router-dom';
import DailyIframe from '@daily-co/daily-js';
import { FlexContainer } from '../Styled/FlexContainer';
import Call from '../components/Call';

const Room = () => {
  const [callObject, setCallObject] = useState(null);
  const [url, setRoomUrl] = useState('');
  const baseUrl = process.env.REACT_APP_DAILY_BASE_URL;
  const { roomName } = useParams();
  //const { search } = useLocation();

  const startJoiningCall = useCallback(() => {
    const callObject = DailyIframe.createCallObject();
    setCallObject(callObject);
    const url = `${baseUrl}${roomName}`;
    console.log(url);
    setRoomUrl(url);
    setCallObject(callObject);
    //setAppState(STATE_JOINING);
    callObject.join({ url });
// eslint-disable-next-line
  }, []);

  useEffect(() => {
    roomName && startJoiningCall();
    // eslint-disable-next-line
  }, [startJoiningCall]);
  return (
    <CallObjectContext.Provider value={callObject}>
      <FlexContainer>
        <Call roomUrl={url}/>
      </FlexContainer>
    </CallObjectContext.Provider>
  );
}
export default Room;