import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 17px;
`;