import {useState, useEffect} from 'react'

const getOrientation = () => {
  const orientation = (window.matchMedia("(orientation: portrait)").matches && 'portrait')
    || (window.matchMedia("(orientation: landscape)").matches && 'landscape')
    || undefined;
  console.log('getOrientation', orientation);
  return orientation;
}

const useScreenOrientation = () => {
  const [orientation, setOrientation] =
    useState(getOrientation())

  const updateOrientation = event => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    window.matchMedia("(orientation: portrait)").onchange = e => {
      updateOrientation(e)
    }
  }, [])

  console.log('orientation', orientation);
  return orientation
}

export default useScreenOrientation