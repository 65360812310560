import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GlobalStyle } from '../Styled/Global';
import { Container } from '../Styled/Container';
import Home from '../views/Home';
import Room from '../views/Room';

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Container>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/:roomName' element={<Room />} />
        </Routes>
      </Container>

    </Router>
  );
}

export default App;
